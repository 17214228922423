var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-12" }, [
      _c(
        "div",
        { staticClass: "form-section" },
        [
          _vm.show
            ? _c(
                "b-form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onSubmit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "col-md-2",
                          attrs: {
                            id: "input-group-environment",
                            label: "environment",
                            "label-for": "input-environment",
                          },
                        },
                        [
                          _c("b-form-select", {
                            staticClass: "mt-3",
                            attrs: { options: _vm.environments, required: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "first",
                                  fn: function () {
                                    return [
                                      _c(
                                        "option",
                                        { domProps: { value: null } },
                                        [_vm._v("Choose...")]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              619859732
                            ),
                            model: {
                              value: _vm.item.environment,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "environment", $$v)
                              },
                              expression: "item.environment",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "col-md-5",
                          attrs: {
                            id: "input-group-platform",
                            label: "platform",
                            "label-for": "input-platform",
                          },
                        },
                        [
                          _c("b-form-select", {
                            staticClass: "mt-3",
                            attrs: { options: _vm.platforms, required: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "first",
                                  fn: function () {
                                    return [
                                      _c(
                                        "option",
                                        { domProps: { value: null } },
                                        [_vm._v("Choose...")]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              619859732
                            ),
                            model: {
                              value: _vm.item.platform,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "platform", $$v)
                              },
                              expression: "item.platform",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "col-md-5",
                          attrs: {
                            id: "input-group-productId",
                            label: "productId",
                            "label-for": "input-productId",
                          },
                        },
                        [
                          _c("b-form-select", {
                            staticClass: "mt-3",
                            attrs: { options: _vm.productIds, required: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "first",
                                  fn: function () {
                                    return [
                                      _c(
                                        "option",
                                        { domProps: { value: null } },
                                        [_vm._v("Choose...")]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              619859732
                            ),
                            model: {
                              value: _vm.item.productId,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "productId", $$v)
                              },
                              expression: "item.productId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "input-group-userId",
                        label: "userId",
                        "label-for": "input-userId",
                      },
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "input-userId",
                          type: "text",
                          required: "",
                          placeholder: "Enter user Id",
                        },
                        model: {
                          value: _vm.item.userId,
                          callback: function ($$v) {
                            _vm.$set(_vm.item, "userId", $$v)
                          },
                          expression: "item.userId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "col-md-6",
                          attrs: {
                            id: "input-group-originalTransactionId",
                            label: "originalTransactionId",
                            "label-for": "input-originalTransactionId",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "input-originalTransactionId",
                              type: "text",
                              required: "",
                              placeholder: "Enter originalTransactionId",
                            },
                            model: {
                              value: _vm.item.originalTransactionId,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "originalTransactionId", $$v)
                              },
                              expression: "item.originalTransactionId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "col-md-6",
                          attrs: {
                            id: "input-group-orderId",
                            label: "orderId",
                            "label-for": "input-orderId",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "input-orderId",
                              type: "text",
                              required: "",
                              placeholder: "Enter orderId",
                            },
                            model: {
                              value: _vm.item.orderId,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "orderId", $$v)
                              },
                              expression: "item.orderId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "col-md-6",
                          attrs: {
                            id: "input-startDateMs",
                            label: "Start Date",
                            "label-for": "input-startDateMs",
                          },
                        },
                        [
                          _c("b-form-datepicker", {
                            staticClass: "mb-2",
                            attrs: {
                              id: "input-startDateMs-input",
                              required: "",
                            },
                            model: {
                              value: _vm.item.startDateMs,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "startDateMs", $$v)
                              },
                              expression: "item.startDateMs",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "col-md-6",
                          attrs: {
                            id: "input-expirationDateMs",
                            label: "Expire Date",
                            "label-for": "input-expirationDateMs",
                          },
                        },
                        [
                          _c("b-form-datepicker", {
                            staticClass: "mb-2",
                            attrs: {
                              id: "input-expirationDateMs-input",
                              required: "",
                            },
                            model: {
                              value: _vm.item.expirationDateMs,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "expirationDateMs", $$v)
                              },
                              expression: "item.expirationDateMs",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.item.isGracePeriod,
                          expression: "item.isGracePeriod",
                        },
                      ],
                      staticClass: "row",
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "col-md-6",
                          attrs: {
                            id: "input-gracePeriodDateMs",
                            label: "GracePeriod Expire Date",
                            "label-for": "input-gracePeriodDateMs",
                          },
                        },
                        [
                          _c("b-form-datepicker", {
                            staticClass: "mb-2",
                            attrs: {
                              id: "input-gracePeriodDateMs-input",
                              required: "",
                            },
                            model: {
                              value: _vm.item.gracePeriodDateMs,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "gracePeriodDateMs", $$v)
                              },
                              expression: "item.gracePeriodDateMs",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: {
                        id: "checkbox-isGracePeriod",
                        name: "isGracePeriod",
                        value: "true",
                        "unchecked-value": "false",
                      },
                      model: {
                        value: _vm.item.isGracePeriod,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "isGracePeriod", $$v)
                        },
                        expression: "item.isGracePeriod",
                      },
                    },
                    [_vm._v("GracePeriod")]
                  ),
                  _vm._v(" " + _vm._s(_vm.item.isGracePeriod) + " "),
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: {
                        id: "checkbox-isCancelled",
                        name: "isCancelled",
                        value: "true",
                        "unchecked-value": "false",
                      },
                      model: {
                        value: _vm.item.isCancelled,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "isCancelled", $$v)
                        },
                        expression: "item.isCancelled",
                      },
                    },
                    [_vm._v("Cancelled")]
                  ),
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: {
                        id: "checkbox-isExpired",
                        name: "isExpired",
                        value: "true",
                        "unchecked-value": "false",
                      },
                      model: {
                        value: _vm.item.isExpired,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "isExpired", $$v)
                        },
                        expression: "item.isExpired",
                      },
                    },
                    [_vm._v("Expired")]
                  ),
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: {
                        id: "checkbox-isActive",
                        name: "isActive",
                        value: "true",
                        "unchecked-value": "false",
                      },
                      model: {
                        value: _vm.item.isActive,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "isActive", $$v)
                        },
                        expression: "item.isActive",
                      },
                    },
                    [_vm._v("Active")]
                  ),
                  _c(
                    "div",
                    { staticClass: "form-submit" },
                    [
                      _c(
                        "b-button",
                        { attrs: { type: "submit", variant: "secondary" } },
                        [
                          _vm.isLoading
                            ? _c("b-spinner", { attrs: { small: "" } })
                            : _vm._e(),
                          _vm._v("Submit "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }