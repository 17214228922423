<template>
  <div class="row">
    <div class="col-md-12">
      <div class="form-section">
        <b-form v-on:submit.prevent="onSubmit" v-if="show">
          <div class="row">
            <b-form-group
              id="input-group-environment"
              label="environment"
              label-for="input-environment"
              class="col-md-2"
            >
              <b-form-select
                class="mt-3"
                v-model="item.environment"
                :options="environments"
                required
              >
                <template v-slot:first>
                  <option :value="null">Choose...</option>
                </template>
              </b-form-select>
            </b-form-group>

            <b-form-group
              id="input-group-platform"
              label="platform"
              label-for="input-platform"
              class="col-md-5"
            >
              <b-form-select
                class="mt-3"
                v-model="item.platform"
                :options="platforms"
                required
              >
                <template v-slot:first>
                  <option :value="null">Choose...</option>
                </template>
              </b-form-select>
            </b-form-group>

            <b-form-group
              id="input-group-productId"
              label="productId"
              label-for="input-productId"
              class="col-md-5"
            >
              <b-form-select
                class="mt-3"
                v-model="item.productId"
                :options="productIds"
                required
              >
                <template v-slot:first>
                  <option :value="null">Choose...</option>
                </template>
              </b-form-select>
            </b-form-group>
          </div>

          <b-form-group
            id="input-group-userId"
            label="userId"
            label-for="input-userId"
          >
            <b-form-input
              id="input-userId"
              v-model="item.userId"
              type="text"
              required
              placeholder="Enter user Id"
            ></b-form-input>
          </b-form-group>

          <div class="row">
            <b-form-group
              id="input-group-originalTransactionId"
              label="originalTransactionId"
              label-for="input-originalTransactionId"
              class="col-md-6"
            >
              <b-form-input
                id="input-originalTransactionId"
                v-model="item.originalTransactionId"
                type="text"
                required
                placeholder="Enter originalTransactionId"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-orderId"
              label="orderId"
              label-for="input-orderId"
              class="col-md-6"
            >
              <b-form-input
                id="input-orderId"
                v-model="item.orderId"
                type="text"
                required
                placeholder="Enter orderId"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="row">
            <b-form-group
              id="input-startDateMs"
              label="Start Date"
              label-for="input-startDateMs"
              class="col-md-6"
            >
              <b-form-datepicker
                id="input-startDateMs-input"
                v-model="item.startDateMs"
                required
                class="mb-2"
              ></b-form-datepicker>
            </b-form-group>

            <b-form-group
              id="input-expirationDateMs"
              label="Expire Date"
              label-for="input-expirationDateMs"
              class="col-md-6"
            >
              <b-form-datepicker
                id="input-expirationDateMs-input"
                v-model="item.expirationDateMs"
                required
                class="mb-2"
              ></b-form-datepicker>
            </b-form-group>
          </div>

          <div class="row" v-show="item.isGracePeriod">
            <b-form-group
              id="input-gracePeriodDateMs"
              label="GracePeriod Expire Date"
              label-for="input-gracePeriodDateMs"
              class="col-md-6"
            >
              <b-form-datepicker
                id="input-gracePeriodDateMs-input"
                v-model="item.gracePeriodDateMs"
                required
                class="mb-2"
              ></b-form-datepicker>
            </b-form-group>
          </div>

          <b-form-checkbox
            id="checkbox-isGracePeriod"
            v-model="item.isGracePeriod"
            name="isGracePeriod"
            value="true"
            unchecked-value="false"
            >GracePeriod</b-form-checkbox
          >
          {{ item.isGracePeriod }}

          <b-form-checkbox
            id="checkbox-isCancelled"
            v-model="item.isCancelled"
            name="isCancelled"
            value="true"
            unchecked-value="false"
            >Cancelled</b-form-checkbox
          >

          <b-form-checkbox
            id="checkbox-isExpired"
            v-model="item.isExpired"
            name="isExpired"
            value="true"
            unchecked-value="false"
            >Expired</b-form-checkbox
          >

          <b-form-checkbox
            id="checkbox-isActive"
            v-model="item.isActive"
            name="isActive"
            value="true"
            unchecked-value="false"
            >Active</b-form-checkbox
          >
          <div class="form-submit">
            <b-button type="submit" variant="secondary">
              <b-spinner small v-if="isLoading"></b-spinner>Submit
            </b-button>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.form-section {
  background-color: white;
  padding: 10px;
  margin-top: 10px;
}
.btn-info {
  background-color: #b5b5c3;
  border-color: #b5b5c3;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import moment from "moment";

export default {
  data() {
    return {
      isLoading: false,
      item: {},
      show: true,
      id: null,
      isEditMode: false,
      platforms: [
        {
          value: "ios",
          text: "Ios"
        },
        {
          value: "android",
          text: "Android"
        }
      ],
      environments: [
        {
          value: "sandbox",
          text: "Sandbox"
        },
        {
          value: "production",
          text: "Production"
        }
      ]
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      let item = { ...this.item };

      item.startDateMs = moment(item.startDateMs).valueOf();
      item.expirationDateMs = moment(item.expirationDateMs).valueOf();
      item.gracePeriodDateMs = item.gracePeriodDateMs
        ? moment(item.gracePeriodDateMs).valueOf()
        : null;

      return new Promise(resolve => {
        this.isLoading = true;
        const url = this.isEditMode
          ? "subscription?id=" + this.id
          : "subscription";

        ApiService.post(url, item)
          .then(({ data }) => {
            this.isLoading = false;
            if (data && data.response && data.response.status) {
              this.$bvToast.toast("Subscription has been saved sucessfully!", {
                title: "Success",
                variant: "success"
              });
              const item = data.response.item;
              this.id = item.id;
              this.isEditMode = true;
            } else {
              this.$bvToast.toast(
                "Something went wrong while saving subscription...",
                {
                  title: "Error",
                  variant: "danger"
                }
              );
            }
            resolve();
          })
          .catch(() => {
            this.isLoading = false;
            this.$bvToast.toast(
              "Something went wrong while saving subscription...",
              {
                title: "Error",
                variant: "danger"
              }
            );
          });
      });
    },
    getItem: function() {
      this.id = this.$route.params.id || this.item.id || this.$route.query.id;
      this.isEditMode = !!this.id;

      if (this.isEditMode) {
        return new Promise(resolve => {
          ApiService.get("subscription", { id: this.id })
            .then(({ data }) => {
              let item =
                data.response && data.response.items.length > 0
                  ? data.response.items[0]
                  : null;
              if (item) {
                item.startDateMs = moment(
                  parseInt(item.startDateMs, 10)
                ).format("YYYY-MM-DD");

                item.expirationDateMs = moment(
                  parseInt(item.expirationDateMs, 10)
                ).format("YYYY-MM-DD");

                item.gracePeriodDateMs = item.gracePeriodDateMs
                  ? moment(parseInt(item.gracePeriodDateMs, 10)).format(
                      "YYYY-MM-DD"
                    )
                  : "";
                this.item = item;
              } else {
                this.item = {};
                this.isEditMode = false;
              }
              resolve();
            })
            .catch(() => {
              this.$bvToast.toast(
                "Something went wrong while retrieving data from external server...",
                {
                  title: "Error",
                  variant: "danger"
                }
              );
            });
        });
      }
    }
  },
  mounted() {
    this.getItem();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Subscriptions", route: "subscriptions" },
      { title: this.isEditMode ? "Edit Subscription" : "Add Subscription" }
    ]);
  },
  computed: {
    productIds() {
      const PRODUCTS = JSON.parse(process.env.PRODUCTS);
      let productIds = [];

      Object.keys(PRODUCTS).forEach(productId => {
        productIds.push({
          value: productId,
          text: PRODUCTS[productId] + " - " + productId
        });
      });

      return productIds;
    }
  }
};
</script>
